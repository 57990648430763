
// user images

export const profileImg = ''

// professional projects



// personal projects

export const netflixImg = 'https://user-images.githubusercontent.com/31513375/167937492-57d093be-30b4-422d-8ee9-bac6dfe5d9be.png'

export const mapEscapeImg = 'https://user-images.githubusercontent.com/31513375/167741493-8e4493b7-8468-48f9-ace1-ecd955f41f24.png'

export const speedReaderImg = 'https://user-images.githubusercontent.com/31513375/168481171-dd30c03a-797e-4aad-aa76-29221e5b37b9.png'

// background

export const genAssemblyImg = 'https://camo.githubusercontent.com/8cb066a7e53ac88c65935eadb69401104d96a9f2511c91f2fd9159aaac5fc18a/68747470733a2f2f67612d636f72652e73332e616d617a6f6e6177732e636f6d2f70726f64756374696f6e2f75706c6f6164732f70726f6772616d2f64656661756c745f696d6167652f353733342f47415f537461636b5f4c617267655f526564426c61636b5f5247422e706e67'

export const cdwImg = 'https://camo.githubusercontent.com/0aa35a857a1a365a7d5324e3e345b78dd9a3adaa178b41fabf687ac2772d5c27/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f7468756d622f622f62652f4344575f4c6f676f2e7376672f3235363070782d4344575f4c6f676f2e7376672e706e67'

export const armyImg = 'https://camo.githubusercontent.com/3eeff5f5eb9aab16650bb879c8c856f8ad302714b0240ca3998ecf694e5e3b8a/68747470733a2f2f75706c6f61642e77696b696d656469612e6f72672f77696b6970656469612f636f6d6d6f6e732f7468756d622f382f38302f5365616c5f6f665f7468655f556e697465645f5374617465735f41726d795f526573657276652e7376672f3130323470782d5365616c5f6f665f7468655f556e697465645f5374617465735f41726d795f526573657276652e7376672e706e67'

export const utImg = 'https://camo.githubusercontent.com/ad17e2f4812442bf2c6494bceb39cd56bc10135f43af823695c219f9ba6a5fb1/68747470733a2f2f7777772e6b696e64706e672e636f6d2f706963632f6d2f3538382d353838363735315f75742d61757374696e2d6c6f676f732d6d6173636f742d756e69766572736974792d6f662d74657861732d61757374696e2e706e67'